import React, { useState, useEffect, useRef } from 'react';
import api from '../../hooks/axios';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,  
    PointElement,
    LineController,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,  
    PointElement,
    LineController,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
);

const MonthlyCustomerChart = ({ initialYear }) => {
    const [selectedYear, setSelectedYear] = useState(initialYear);
    const [isYearDropdownOpen, setIsYearDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const years = [2023, 2024];
    
    const [monthlyData, setMonthlyData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await api.get(`/api/counsel/infos/${selectedYear}`);
                
                let dataArray = response.data.data;
                if (dataArray && Array.isArray(dataArray)) {
                    dataArray = dataArray.sort((a, b) => a.month - b.month);
                }
                
                setMonthlyData(dataArray || []);
                setError(null);
            } catch (err) {
                setError(err.message);
                setMonthlyData([]);
            } finally {
                setLoading(false);
            }
        };
    
        fetchData();
    }, [selectedYear]);

    useEffect(() => {
        const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsYearDropdownOpen(false);
        }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const months = [
        '1월', '2월', '3월', '4월', '5월', '6월',
        '7월', '8월', '9월', '10월', '11월', '12월'
    ];

    const backgroundColors = [
        'rgba(255, 99, 132, 0.5)',   // 빨강
        'rgba(54, 162, 235, 0.5)',   // 파랑
        'rgba(255, 206, 86, 0.5)',   // 노랑
        'rgba(75, 192, 192, 0.5)',   // 청록
        'rgba(153, 102, 255, 0.5)',  // 보라
        'rgba(255, 159, 64, 0.5)',   // 주황
        'rgba(199, 199, 199, 0.5)',  // 회색
        'rgba(83, 102, 255, 0.5)',   // 남색
        'rgba(255, 99, 255, 0.5)',   // 분홍
        'rgba(159, 159, 64, 0.5)',   // 올리브
        'rgba(255, 159, 159, 0.5)',  // 연한 빨강
        'rgba(159, 255, 159, 0.5)'   // 연한 초록
    ];

    const borderColors = backgroundColors.map(color =>
        color.replace('0.5', '1')
    );

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    font: {
                        size: function(context) {
                            let width = context.chart.width;
                            return width < 512 ? 12 : width < 768 ? 14 : 16;
                        }
                    }
                }
            },
            title: {
                display: true,
                text: `${selectedYear}년 전체 고객 수`,
                font: {
                    size: function(context) {
                        let width = context.chart.width;
                        return width < 512 ? 14 : width < 768 ? 16 : 18;
                    },
                    weight: 'bold'
                },
                padding: {
                    top: 10,
                    bottom: 10
                }
            },
            tooltip: {
                enabled: true,
                callbacks: {
                    label: function(context) {
                        const originalValue = monthlyData[context.dataIndex].count;
                        return `고객 수: ${originalValue}명`;
                    }
                }
            },
            datalabels: {
                display: function(context) {
                    // 라인 차트의 데이터 라벨은 표시하지 않음
                    return context.datasetIndex === 0;
                },
                color: 'black',
                anchor: 'end',
                align: 'end',
                offset: -2,
                font: {
                    weight: 'bold',
                    size: function(context) {
                        let width = context.chart.width;
                        return width < 512 ? 10 : width < 768 ? 12 : 14;
                    }
                },
                formatter: function(value, context) {
                    const originalValue = monthlyData[context.dataIndex].count;
                    return originalValue + '명';
                }
            }
        },
        scales: {
            y: {
                beginAtZero: true,
                grid: {
                    drawBorder: false,
                    color: 'rgba(0, 0, 0, 0.1)'
                },
                ticks: {
                    font: {
                        size: function(context) {
                            let width = context.chart.width;
                            return width < 512 ? 10 : width < 768 ? 12 : 14;
                        }
                    },
                    padding: 5
                },
                title: {
                    display: true,
                    text: '고객 수',
                    font: {
                        size: function(context) {
                            let width = context.chart.width;
                            return width < 512 ? 12 : width < 768 ? 14 : 16;
                        },
                        weight: 'bold'
                    },
                    padding: {
                        top: 10,
                        bottom: 10
                    }
                }
            },
            x: {
                grid: {
                    display: false,
                    drawBorder: false
                },
                ticks: {
                    font: {
                        size: function(context) {
                            let width = context.chart.width;
                            return width < 512 ? 10 : width < 768 ? 12 : 14;
                        }
                    },
                    padding: 5
                },
                title: {
                    display: true,
                    text: '월',
                    font: {
                        size: function(context) {
                            let width = context.chart.width;
                            return width < 512 ? 12 : width < 768 ? 14 : 16;
                        },
                        weight: 'bold'
                    },
                    padding: {
                        top: 10,
                        bottom: 10
                    }
                }
            }
        },
        animation: false,
        responsiveAnimationDuration: 0,
        elements: {
            bar: {
                borderWidth: 1
            }
        },
        layout: {
            padding: {
                left: 10,
                right: 10,
                top: 0,
                bottom: 10
            }
        },
        interaction: {
            intersect: false,
            mode: 'index',
        },
    };
    
    const chartData = {
        labels: months,
        datasets: [
            {
                label: '월별 고객 수',
                data: Array.isArray(monthlyData) ? monthlyData.map(item => item.count) : [],
                backgroundColor: backgroundColors,
                borderColor: borderColors,
                borderWidth: 1,
                barThickness: 'flex',
                barPercentage: 0.8,
                borderRadius: 3,
                order: 2  // 막대 그래프를 뒤에 그리도록 설정
            },
            {
                label: '추세선',
                data: Array.isArray(monthlyData) ? monthlyData.map(item => item.count) : [],
                type: 'line',  // 라인 차트로 설정
                fill: false,
                borderColor: 'rgb(255, 0, 0)',  // 빨간색 선
                borderWidth: 2,
                pointBackgroundColor: 'rgb(255, 0, 0)',
                pointBorderColor: 'rgb(255, 0, 0)',
                pointRadius: 4,
                pointHoverRadius: 6,
                tension: 0.2,  // 선을 부드럽게 만듦
                order: 1  // 라인을 앞에 그리도록 설정
            }
        ],
    };

    const renderYearSelector = () => (
        <div className="flex justify-end mb-4">
            <div className="relative" ref={dropdownRef}>
                <button
                    onClick={() => setIsYearDropdownOpen(!isYearDropdownOpen)}
                    className="flex items-center justify-between px-4 py-2 text-sm sm:text-base bg-white border rounded-lg shadow-sm hover:bg-gray-50 min-w-[120px] sm:min-w-[140px] focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                    <span>{selectedYear}년</span>
                    <svg
                        className={`w-4 h-4 ml-2 transition-transform duration-200 ${
                            isYearDropdownOpen ? 'transform rotate-180' : ''
                        }`}
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                        />
                    </svg>
                </button>

                {isYearDropdownOpen && (
                    <div className="absolute right-0 z-10 w-full mt-1 bg-white border rounded-lg shadow-lg">
                        {years.map((year) => (
                            <button
                                key={year}
                                onClick={() => {
                                    setSelectedYear(year);
                                    setIsYearDropdownOpen(false);
                                }}
                                className={`block w-full px-4 py-2 text-sm sm:text-base text-left hover:bg-gray-100 focus:outline-none
                                    ${year === selectedYear ? 'bg-gray-50 font-semibold' : ''}
                                    ${year === years[0] ? 'rounded-t-lg' : ''}
                                    ${year === years[years.length - 1] ? 'rounded-b-lg' : ''}`}
                            >
                                {year}년
                            </button>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );

    const renderContent = () => {
        if (loading) {
            return (
                <div className="absolute inset-0 flex items-center justify-center">
                    <div className="flex items-center space-x-2">
                        <div className="w-4 h-4 border-2 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
                        <span className="text-gray-500">데이터를 불러오는 중...</span>
                    </div>
                </div>
            );
        }

        if (error) {
            return (
                <div className="absolute inset-0 flex items-center justify-center text-red-500">
                    데이터를 불러오는데 실패했습니다: {error}
                </div>
            );
        }

        if (monthlyData.length === 0) {
            return (
                <div className="absolute inset-0 flex items-center justify-center">
                    데이터가 없습니다.
                </div>
            );
        }

        return <Bar options={options} data={chartData} />;
    };

    return (
        <div className="w-full h-[400px] md:h-[500px] lg:h-[600px] p-4 bg-white rounded-lg shadow">
            {renderYearSelector()}
            <div className="relative w-full h-full">
                {renderContent()}
            </div>
        </div>
    );
};

export default MonthlyCustomerChart;