import React, { useState } from 'react';
import { Eye, PlusCircle, Save, X, Edit, Trash2 } from 'lucide-react';
import GridLayout from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import api from '../../hooks/axios';

const Modal = ({ isOpen, onClose, title, children }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-6 relative max-h-[90vh] overflow-y-auto" style={{ width: '340px' }}>
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-bold">{title}</h2>
                    <button
                        onClick={onClose}
                        className="text-gray-500 hover:text-gray-700"
                    >
                        <X className="w-5 h-5" />
                    </button>
                </div>
                <div>
                    {children}
                </div>
            </div>
        </div>
    );
};

const elementTypes = [
    { id: 'text', label: '텍스트' },
    { id: 'number', label: '번호' },
    { id: 'time', label: '시간' },
    { id: 'image', label: '이미지' },
];

const NumberTicket = ({ deptId }) => {
    
    const [ticketElements, setTicketElements] = useState([]);
    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
    const [editingElement, setEditingElement] = useState(null);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [newElementContent, setNewElementContent] = useState('');
    const [selectedElementType, setSelectedElementType] = useState('text');
    const [selectedElement, setSelectedElement] = useState(null);

    const [layouts, setLayouts] = useState(
        ticketElements.map(item => ({
            i: item.id,
            ...item.layout
        }))
    );
    // 데이터 로드 상태 추가
    const [isLoading, setIsLoading] = useState(true);
    // 초기 데이터 로드
    React.useEffect(() => {
        if (!deptId) {
            setIsLoading(false);
            return;
        }

        const fetchTicketData = async () => {
            try {
                setIsLoading(true);
                const response = await api.get(`/api/ticket/${deptId}`);
                
                if (response.data.data) {
                    // 받아온 데이터를 ticketElements 형식에 맞게 변환
                    const formattedElements = response.data.data.map(item => ({
                        id: item.elementId,
                        type: item.type,
                        content: item.content,
                        isEnabled: true,
                        layout: {
                            x: item.layoutX,
                            y: item.layoutY,
                            w: item.layoutW,
                            h: item.layoutH
                        }
                    }));

                    setTicketElements(formattedElements);
                    setLayouts(formattedElements.map(item => ({
                        i: item.id,
                        ...item.layout
                    })));
                }
            } catch (error) {
                console.error('데이터를 불러오는 중 오류 발생:', error);
                alert('데이터를 불러오는데 실패했습니다.');
            } finally {
                setIsLoading(false);
            }
        };

        fetchTicketData();
    }, [deptId]);

    // 로딩 중일 때 표시할 내용

    const handleOutsideClick = (e) => {
        if (!e.target.closest('.ticket-element')) {
            setSelectedElement(null);
        }
    };

    React.useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    const handleEditElement = (element) => {
        setEditingElement(element);
    };

    const handleLayoutChange = (newLayout) => {
        setLayouts(newLayout);
        setTicketElements(prev => 
            prev.map(item => {
                const layoutItem = newLayout.find(l => l.i === item.id);
                if (layoutItem) {
                    return {
                        ...item,
                        layout: {
                            x: layoutItem.x,
                            y: layoutItem.y,
                            w: layoutItem.w,
                            h: layoutItem.h
                        }
                    };
                }
                return item;
            })
        );
    };

    const handleSaveEdit = (newContent) => {
        setTicketElements(elements =>
            elements.map(el =>
                el.id === editingElement.id ? { ...el, content: newContent } : el
            )
        );
        setEditingElement(null);
    };

    const handleAddElement = () => {
        if (!newElementContent) return;

        const newElement = {
            id: `${selectedElementType}-${Date.now()}`,
            type: selectedElementType,
            content: newElementContent,
            isEnabled: true,
            layout: { x: 0, y: Infinity, w: 8, h: 2 } // 새 요소는 맨 아래에 추가
        };

        setTicketElements(prev => [...prev, newElement]);
        setLayouts(prev => [...prev, {
            i: newElement.id,
            ...newElement.layout
        }]);

        setNewElementContent('');
        setIsAddModalOpen(false);
    };

    const handleSave = async () => {
        try {
            const saveData = {
                elements: ticketElements.map(el => ({
                    elementId: el.id,
                    type: el.type,
                    content: el.content,
                    layoutX: el.layout.x,
                    layoutY: el.layout.y,
                    layoutW: el.layout.w,
                    layoutH: el.layout.h
                }))
            };
            
            const response = await api.post(`/api/ticket/save/${deptId}`, saveData.elements);
            alert('순번표 설정이 저장되었습니다.');
        } catch (error) {
            console.error('저장 중 오류 발생:', error);
            alert('저장 중 오류가 발생했습니다.');
        }
    };
    const handleDeleteElement = (elementId) => {
        if (window.confirm('이 요소를 삭제하시겠습니까?')) {
            setTicketElements(prev => prev.filter(el => el.id !== elementId));
            setLayouts(prev => prev.filter(layout => layout.i !== elementId));
        }
    };

    const TicketPreview = () => (
        <div className="bg-white rounded-lg" style={{ width: '302px' }}>
            <GridLayout
                className="layout"
                layout={layouts}
                cols={8}
                rowHeight={30}
                width={302}
                isResizable={false}
                margin={[5, 5]}
                containerPadding={[5, 5]}
            >
                {ticketElements.map((element) => (
                    <div key={element.id} className="border p-2 rounded">
                        {element.type === 'logo' || element.type === 'image' ? (
                            <img src={element.content} alt="Logo" className="h-full w-full object-contain" />
                        ) : (
                            <div className="text-lg">{element.content}</div>
                        )}
                    </div>
                ))}
            </GridLayout>
        </div>
    );
    if (isLoading) {
        return (
            <div className="p-6 flex justify-center items-center">
                <div className="text-lg">데이터를 불러오는 중...</div>
            </div>
        );
    }
    return (
        <div className="p-6">
            <div className="mb-8">
                <h1 className="text-2xl font-bold mb-4">순번표 커스터마이징</h1>
                <div className="flex gap-2">
                    <button
                        onClick={() => setIsAddModalOpen(true)}
                        className="flex items-center gap-2 px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
                    >
                        <PlusCircle className="w-4 h-4" />
                        요소 추가
                    </button>
                    <button
                        onClick={() => setIsPreviewModalOpen(true)}
                        className="flex items-center gap-2 px-4 py-2 bg-white border rounded-lg hover:bg-gray-50"
                    >
                        <Eye className="w-4 h-4" />
                        미리보기
                    </button>
                    <button
                        onClick={handleSave}
                        className="flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                    >
                        <Save className="w-4 h-4" />
                        저장하기
                    </button>
                </div>
            </div>
     
            <div className="bg-white p-4 rounded-lg shadow-lg mb-6" style={{ width: '332px' }}>
                <GridLayout
                    className="layout"
                    layout={layouts}
                    cols={8}
                    rowHeight={30}
                    width={302}
                    onLayoutChange={handleLayoutChange}
                    draggableHandle=".drag-handle"
                    margin={[5, 5]}
                    containerPadding={[5, 5]}
                >
                    {ticketElements.map((element) => (
                        <div 
                            key={element.id} 
                            className={`ticket-element border rounded relative group ${
                                selectedElement?.id === element.id ? 'ring-2 ring-blue-500' : ''
                            }`}
                            onClick={(e) => {
                                e.stopPropagation();
                                setSelectedElement(element);
                            }}
                        >
                            <div className="flex justify-between items-center p-2 bg-gray-50">
                                <span className="text-sm font-medium drag-handle cursor-move">{element.type}</span>
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        handleDeleteElement(element.id);
                                    }}
                                    className="p-1 hover:bg-red-100 rounded flex items-center"
                                    title="삭제"
                                >
                                    <Trash2 className="w-4 h-4 text-red-500" />
                                </button>
                            </div>
                            <div className="p-2">
                                {element.type === 'logo' || element.type === 'image' ? (
                                    <img src={element.content} alt="Logo" className="h-full w-full object-contain" />
                                ) : (
                                    <div>{element.content}</div>
                                )}
                            </div>
                            {selectedElement?.id === element.id && (
                                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleEditElement(element);
                                        }}
                                        className="w-[90%] h-[80%] bg-white text-black rounded-lg hover:bg-gray-100 flex items-center justify-center gap-2"
                                    >
                                        <Edit className="w-5 h-5" />
                                        편집하기
                                    </button>
                                </div>
                            )}
                        </div>
                    ))}
                </GridLayout>
            </div>
     
            {/* 미리보기 모달 */}
            <Modal isOpen={isPreviewModalOpen} onClose={() => setIsPreviewModalOpen(false)} title="순번표 미리보기">
                <TicketPreview />
            </Modal>
     
            {/* 편집 모달 */}
            <Modal isOpen={!!editingElement} onClose={() => setEditingElement(null)} title="요소 편집">
                <div className="space-y-4">
                    <input
                        type="text"
                        defaultValue={editingElement?.content}
                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleSaveEdit(e.target.value);
                            }
                        }}
                    />
                    <button
                        onClick={() => handleSaveEdit(document.querySelector('input').value)}
                        className="w-full px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                    >
                        저장
                    </button>
                </div>
            </Modal>
     
            {/* 요소 추가 모달 */}
            <Modal isOpen={isAddModalOpen} onClose={() => setIsAddModalOpen(false)} title="새 요소 추가">
                <div className="space-y-4">
                    <div>
                        <label className="block text-sm font-medium mb-1">요소 유형</label>
                        <select
                            value={selectedElementType}
                            onChange={(e) => setSelectedElementType(e.target.value)}
                            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                            {elementTypes.map(type => (
                                <option key={type.id} value={type.id}>
                                    {type.label}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label className="block text-sm font-medium mb-1">내용</label>
                        <input
                            type="text"
                            value={newElementContent}
                            onChange={(e) => setNewElementContent(e.target.value)}
                            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder={selectedElementType === 'image' ? "이미지 URL을 입력하세요" : "내용을 입력하세요"}
                        />
                    </div>
                    <button
                        onClick={handleAddElement}
                        className="w-full px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                    >
                        추가하기
                    </button>
                </div>
            </Modal>
        </div>
     );
};

export default NumberTicket;