import React, { useState, useEffect} from 'react';
import { atom, useRecoilState } from 'recoil';
import { BusinessNavigationItem } from "../../components/BusinessNavigationItem";
import { BusinessNavigationItems } from "../../constants/BusinessNavigationItems";
import CustomerKiosk from "../../components/business/CustomerKiosk";
import KioskCustom from "../../components/business/KioskCustom";
import NumberTicket from "../../components/business/NumberTicket";
import BranchLayoutCustom from "../../components/business/BranchLayoutCustom";
import api from '../../hooks/axios';

export const businessSidebarState = atom({
    key: 'businessSidebarState',
    default: false
});

const Business = () => {
    const [sidebarOpen, setSidebarOpen] = useRecoilState(businessSidebarState);
    // const dropdownRef = useRef(null);
    const [activeComponent, setActiveComponent] = useState('kiosk'); //현재 활성화된 컴포넌트
    const [deptName, setDeptName] = useState('');
    const [navigationItems, setNavigationItems] = useState([]);
    const [deptId, setDeptId] = useState(''); // deptId를 위한 state 추가
    
    useEffect(() => {
        const fetchDeptInfo = async () => {
            try {
                const response = await api.get('/api/user/bank'); // API 엔드포인트는 실제 경로로 수정
                if (response.data.code === 200) {
                    setDeptName(response.data.data.deptName);
                    setDeptId(response.data.data.deptId);
                    setNavigationItems(BusinessNavigationItems(deptName));
                }
            } catch (error) {
                console.error('부서 정보 조회 실패:', error);
                setNavigationItems(BusinessNavigationItems()); // 에러 시 기본값 사용
            }
        };

        fetchDeptInfo();
    }, []);

    return (
        <div className="h-screen flex flex-col">
        {/* 토글 버튼 - 사이드바가 닫혀있을 때만 보임 */}
        {!sidebarOpen && (
        <button
            onClick={() => setSidebarOpen(true)}
            type="button"
            className="fixed top-20 left-4 inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 z-30"
        >
            <span className="sr-only">Open sidebar</span>
            <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z" />
            </svg>
        </button>
        )}

        {/* 컨텐츠 영역을 flex로 구성 */}
        <div className="flex-1 flex overflow-hidden">
            {/* Sidebar */}
            <aside 
            className={`fixed top-16 left-0 z-20 transition-transform ${
                sidebarOpen ? 'translate-x-0' : '-translate-x-full'
            } w-52 sm:w-56 md:w-64 lg:w-72 h-[calc(100vh-64px)]`}
            aria-label="Sidebar"
            >
            <div className="h-full px-2 sm:px-3 py-3 sm:py-4 overflow-y-auto bg-gray-50">
                {/* 뒤로가기 버튼 */}
                {sidebarOpen && (
                <button
                    onClick={() => setSidebarOpen(false)}
                    className="w-full flex items-center p-1 sm:p-2 text-gray-900 rounded-lg hover:bg-gray-100 mb-2"
                >
                    <svg 
                    className="w-4 h-4 sm:w-6 sm:h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M10 19l-7-7m0 0l7-7m-7 7h18"
                    />
                    </svg>
                    <span className="ml-2 sm:ml-3 text-sm sm:text-base">Back</span>
                </button>
                )}
                <ul className="space-y-1 sm:space-y-2 font-medium">
                {navigationItems.map((item, index) => (
                    <BusinessNavigationItem
                    key={index} 
                    item={item}
                    activeComponent={activeComponent}
                    setActiveComponent={setActiveComponent}
                    />
                ))}
                </ul>
            </div>
            </aside>
            
            {/* Main content - 스크롤 가능한 영역 */}
            <main className={`flex-1 overflow-y-auto transition-margin duration-300 ${
                sidebarOpen ? 'ml-52 sm:ml-56 md:ml-64 lg:ml-72' : 'ml-0'
            }`}>
                <div className="h-full flex justify-center"> {/* 중앙 정렬을 위한 flex 추가 */}
                    {activeComponent === 'kiosk' ? (
                        <CustomerKiosk
                            deptId={deptId}
                            deptName={deptName}
                            activeComponent={activeComponent}
                        />
                    ) : activeComponent === 'kioskcustom' ? (
                        <KioskCustom deptId={deptId} deptName={deptName} activeComponent="kiosk" />
                    ) : activeComponent === 'numberticket' ?(
                                    <NumberTicket deptId={deptId} />
                    ) : <BranchLayoutCustom deptId={deptId}/>}
                </div>
            </main>
        </div>
        </div>
    )
}
export default Business;