import React from 'react';
import '../../css/Home.css';
import BackgroundVideo  from "../../components/BackgroundVideo";



const Home = () => {
  return (

      <div className="relative h-screen">
        <BackgroundVideo />
        <div className="relative z-10">
          <div className="text-white p-4">
            {/* Additional content can go here */}
          </div>
        </div>
      </div>
  );
};
export default Home;