import BranchDailyStatsChart from './BranchDailyStatsChart';

const BranchTimeStats = () => {
  return (
    <div className="w-full h-auto min-h-[400px] md:h-[500px] lg:h-[600px] p-4 bg-white rounded-lg shadow">
      <BranchDailyStatsChart />
    </div>
  );
};

export default BranchTimeStats;