import React, { useState, useEffect, useRef } from 'react';
import api from '../../hooks/axios';
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    Title
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    Title,
    ChartDataLabels
);

const BranchCustomerChart = ({ initialYear }) => {
    const [selectedYear, setSelectedYear] = useState(initialYear);
    const [isYearDropdownOpen, setIsYearDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const years = [2023, 2024];
    const [branchData, setBranchData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await api.get(`/api/counsel/count/${selectedYear}`);
                
                setBranchData(response.data.data || []);
                setError(null);
            } catch (err) {
                setError(err.message);
                setBranchData([]);
            } finally {
                setLoading(false);
            }
        };
    
        fetchData();
    }, [selectedYear]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsYearDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const colors = [
        'rgba(255, 99, 132, 0.8)',    // 연한 빨강
        'rgba(54, 162, 235, 0.8)',    // 하늘색
        'rgba(255, 206, 86, 0.8)',    // 노랑
        'rgba(75, 192, 192, 0.8)',    // 청록
        'rgba(153, 102, 255, 0.8)',   // 보라
        'rgba(255, 159, 64, 0.8)',    // 주황
        'rgba(123, 239, 178, 0.8)',   // 민트
        'rgba(255, 127, 80, 0.8)',    // 코랄
        'rgba(147, 112, 219, 0.8)',   // 라벤더
        'rgba(60, 179, 113, 0.8)',    // 초록
        'rgba(238, 130, 238, 0.8)',   // 바이올렛
        'rgba(106, 90, 205, 0.8)',    // 슬레이트블루
        'rgba(250, 128, 114, 0.8)',   // 연어색
        'rgba(95, 158, 160, 0.8)',    // 카데트블루
        'rgba(221, 160, 221, 0.8)',   // 플럼
        'rgba(176, 196, 222, 0.8)',   // 라이트스틸블루
        'rgba(255, 182, 193, 0.8)',   // 라이트핑크
        'rgba(255, 218, 185, 0.8)',   // 피치퍼프
        'rgba(152, 251, 152, 0.8)',   // 페일그린
        'rgba(135, 206, 235, 0.8)',   // 스카이블루
        'rgba(219, 112, 147, 0.8)',   // 팔레바이올렛레드
        'rgba(240, 230, 140, 0.8)',   // 카키
        'rgba(189, 183, 107, 0.8)',   // 다크카키
        'rgba(143, 188, 143, 0.8)'    // 다크씨그린
    ];

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'right',
                labels: {
                    font: {
                        size: function(context) {
                            let width = context.chart.width;
                            return width < 512 ? 12 : width < 768 ? 14 : 16;
                        }
                    },
                    padding: 20
                }
            },
            title: {
                display: true,
                text: `${selectedYear}년 영업점별 고객 수`,
                font: {
                    size: function(context) {
                        let width = context.chart.width;
                        return width < 512 ? 14 : width < 768 ? 16 : 18;
                    },
                    weight: 'bold'
                },
                padding: {
                    top: 10,
                    bottom: 30
                }
            },
            datalabels: {
                color: 'white',
                font: {
                    weight: 'bold',
                    size: function(context) {
                        let width = context.chart.width;
                        return width < 512 ? 10 : width < 768 ? 12 : 14;
                    }
                },
                formatter: function(value, context) {
                    const total = context.dataset.data.reduce((acc, cur) => acc + cur, 0);
                    const percentage = ((value / total) * 100).toFixed(1);
                    return `${value}명\n(${percentage}%)`;
                }
            },
            hover: {
                mode: 'single',
                animationDuration: 300
            },
            // 애니메이션 설정 추가
            animation: {
                animateScale: true,
                animateRotate: true,
            },
            // 각 섹션의 hover 동작 설정
            elements: {
                arc: {
                    hoverOffset: 50,  // hover 시 튀어나오는 정도
                    borderWidth: 0,
                    hoverBorderWidth: 2,
                    hoverBorderColor: 'white',
                    borderRadius: 5,  // 도넛 모서리를 약간 둥글게
                    transition: 'all 0.3s ease'
                }
            },
            // 상호작용 설정
            interaction: {
                mode: 'nearest',
                intersect: true,
                axis: 'xy'
            }
        },
    };
    
    const chartData = {
        labels: branchData.map(item => item.deptName),
        datasets: [
            {
                data: branchData.map(item => item.count),
                backgroundColor: colors.slice(0, branchData.length),
                borderWidth: 0
            },
        ],
    };

    const renderYearSelector = () => (
        <div className="flex justify-end mb-4">
            <div className="relative" ref={dropdownRef}>
                <button
                    onClick={() => setIsYearDropdownOpen(!isYearDropdownOpen)}
                    className="flex items-center justify-between px-4 py-2 text-sm sm:text-base bg-white border rounded-lg shadow-sm hover:bg-gray-50 min-w-[120px] sm:min-w-[140px] focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                    <span>{selectedYear}년</span>
                    <svg
                        className={`w-4 h-4 ml-2 transition-transform duration-200 ${
                            isYearDropdownOpen ? 'transform rotate-180' : ''
                        }`}
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                        />
                    </svg>
                </button>

                {isYearDropdownOpen && (
                    <div className="absolute right-0 z-10 w-full mt-1 bg-white border rounded-lg shadow-lg">
                        {years.map((year) => (
                            <button
                                key={year}
                                onClick={() => {
                                    setSelectedYear(year);
                                    setIsYearDropdownOpen(false);
                                }}
                                className={`block w-full px-4 py-2 text-sm sm:text-base text-left hover:bg-gray-100 focus:outline-none
                                    ${year === selectedYear ? 'bg-gray-50 font-semibold' : ''}
                                    ${year === years[0] ? 'rounded-t-lg' : ''}
                                    ${year === years[years.length - 1] ? 'rounded-b-lg' : ''}`}
                            >
                                {year}년
                            </button>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );

    const renderContent = () => {
        if (loading) {
            return (
                <div className="absolute inset-0 flex items-center justify-center">
                    <div className="flex items-center space-x-2">
                        <div className="w-4 h-4 border-2 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
                        <span className="text-gray-500">데이터를 불러오는 중...</span>
                    </div>
                </div>
            );
        }

        if (error) {
            return (
                <div className="absolute inset-0 flex items-center justify-center text-red-500">
                    데이터를 불러오는데 실패했습니다: {error}
                </div>
            );
        }

        if (branchData.length === 0) {
            return (
                <div className="absolute inset-0 flex items-center justify-center">
                    데이터가 없습니다.
                </div>
            );
        }

        return <Doughnut options={options} data={chartData} />;
    };

    return (
        <div className="w-full h-[400px] md:h-[500px] lg:h-[600px] p-4 bg-white rounded-lg shadow">
            {renderYearSelector()}
            <div className="relative w-full h-full">
                {renderContent()}
            </div>
        </div>
    );
};

export default BranchCustomerChart;