import React, { useState, useEffect } from 'react';
import api from '../../hooks/axios';
import aiImage from '../../assets/ai.png';
import logo1 from '../../assets/logo1.png';

const CustomerKiosk = ({ deptId, deptName, activeComponent }) => {
   const [kioskServices, setKioskServices] = useState(null);
   const [loading, setLoading] = useState(true);
   const [error, setError] = useState(null);

   
   const formatServiceName = (name) => {
    if (name === "일반업무") return "일반\n업무";
    if (name === "대출업무") return "대출\n업무";
    if (name === "환전업무") return "환전\n업무";
    if (name === "대구로페이 업무") return "대구로페이\n업무";
    if (name === "기업대출업무") return "기업대출\n업무";
    return name;
    };

   const serviceNames = {
       normal: "일반업무",
       loan: "대출업무",
       exchange: "환전업무",
       pay: "대구로페이 업무",
       corLoan: "기업대출업무"
   };

   // 버튼 색상을 결정하는 함수
   const getButtonColor = (index) => {
       const colors = [
           'bg-[#98D8AA] hover:bg-[#89C99E]', // 파스텔 초록
           'bg-[#94B9F0] hover:bg-[#85AAE1]', // 파스텔 블루
           'bg-[#FFB6B6] hover:bg-[#F0A7A7]', // 파스텔 핑크
           'bg-[#FFE69A] hover:bg-[#F0D78B]', // 파스텔 옐로우
           'bg-[#B4A7D6] hover:bg-[#A598C7]'  // 파스텔 퍼플
       ];
       return colors[index % colors.length];
   };

   const handleSearch = async () => {
       if (!deptId) return;
       
       try {
           setLoading(true);
           const response = await api.get(`/api/kiosk/${deptId}`);
           if (response.data.code === 200) {
               setKioskServices(response.data.data);
               setError(null);
           }
       }
       catch (err) {
           setError('데이터를 불러오는데 실패했습니다.');
           console.error('Error fetching daily data:', err);
           setKioskServices([]);
       } finally {
           setLoading(false);
       }
   };

   useEffect(() => {
       if (deptId && activeComponent === 'kiosk') {
           handleSearch();
       }
       
       return () => {
           setKioskServices(null);
           setLoading(true);
           setError(null);
       };
   }, [deptId, activeComponent]);
    const activeServices = kioskServices ? 
            Object.entries(kioskServices)
                // 값이 0이 아닌 것만 필터링
                .filter(([_, value]) => value > 0)
                // value(순서)를 기준으로 정렬
                .sort((a, b) => a[1] - b[1])
                // serviceType을 serviceName으로 변환
                .map(([serviceType, _]) => serviceNames[serviceType])
            : [];
   if (loading) {
       return <div>로딩 중...</div>;
   }

   if (error) {
       return <div>에러: {error}</div>;
   }

    return (
    <div className="w-[1080px] h-[1920px] overflow-auto"> 
        <div className="flex flex-col h-full">
            {/* 부서명과 로고 섹션 - 높이 증가 및 여백 조정 */}
            <div className="w-full h-[200px] flex flex-col justify-center items-center mt-10">
                {/* 로고를 위로 이동하고 크기 증가 */}
                <div className="mb-8">
                    <img 
                        src={logo1} 
                        alt="Bank Logo"
                        className="h-[100px] w-auto object-contain" // 로고 크기 증가
                    />
                </div>
                {/* 부서명에 마진 추가 */}
                <h1 className="text-5xl font-bold text-gray-800 mt-4">{deptName}</h1>
            </div>
    
            {/* 메인 컨텐츠 섹션 */}
            <div className="flex justify-center flex-1"> 
                {/* 이미지 섹션 */}
                <div className="w-[500px] flex justify-center items-center">
                    <div className="w-full h-[1300px]">
                        <img 
                            src={aiImage} 
                            alt="Bank Service"
                            className="w-full h-full object-cover"
                        />
                    </div>
                </div>
                
                {/* 서비스 목록 섹션 */}
                <div className="w-[520px] flex items-center justify-center">
                    {activeServices.length > 0 ? (
                        <div className="w-full flex justify-center">
                            <div className="flex flex-col gap-10">
                                {activeServices.map((serviceName, index) => (
                                    <div 
                                        key={index}
                                        className={`p-8 rounded-2xl transition-colors cursor-pointer shadow-lg ${getButtonColor(index)} w-[250px] h-[200px] flex items-center justify-center`}
                                    >
                                        <span className="text-4xl font-bold text-white whitespace-pre-line text-center">
                                            {formatServiceName(serviceName)}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <p className="text-2xl text-center text-gray-500 w-full">현재 제공 중인 서비스가 없습니다.</p>
                    )}
                </div>
            </div>
        </div>
    </div>
    );
};

export default CustomerKiosk;