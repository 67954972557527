import React, { useState } from 'react';
import { PlusCircle, Save, X, Edit, DoorOpen, Monitor, MinusCircle } from 'lucide-react';
import GridLayout from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import api from '../../hooks/axios';

const Modal = ({ isOpen, onClose, title, children }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-6 relative max-h-[90vh] overflow-y-auto" style={{ width: '340px' }}>
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-bold">{title}</h2>
                    <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                        <X className="w-5 h-5" />
                    </button>
                </div>
                <div>{children}</div>
            </div>
        </div>
    );
};

const elementTypes = [
    { id: 'entrance', label: '출입구', icon: DoorOpen },
    { id: 'counter', label: '창구', icon: Monitor },
];

const BranchLayoutCustom = ({ deptId }) => {
    // 층 관련 상태
    const [floors, setFloors] = useState([1]); // 층 번호 배열
    const [currentFloor, setCurrentFloor] = useState(1); // 현재 선택된 층
    const [layoutSize, setLayoutSize] = useState({ width: 800, height: 600 });
    const [isLoading, setIsLoading] = useState(true);

    // elementId에서 type 추출하는 helper 함수
    const getTypeFromElementId = (elementId) => {
        if (elementId.startsWith('entrance-')) return 'entrance';
        if (elementId.startsWith('counter-')) return 'counter';
        return 'counter'; // 기본값
    };

    // 각 층별 레이아웃 요소 상태
    const [layoutElements, setLayoutElements] = useState({
        1: [] // 초기 1층 빈 레이아웃
    });

    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [selectedElementType, setSelectedElementType] = useState('entrance');
    const [newElementContent, setNewElementContent] = useState('');


    // 초기 데이터 로드
    // 초기 데이터 로드
// 초기 데이터 로드
React.useEffect(() => {
    const fetchLayoutData = async () => {
        try {
            setIsLoading(true);
            
            // 1. 레이아웃 정보 fetch (각 층의 크기 정보)
            const layoutResponse = await api.get(`/api/layout/${deptId}`);
            
            
            let floorNumbers = [1]; // 기본값
            let initialSize = { width: 800, height: 600 }; // 기본 크기

            if (layoutResponse?.data?.data && Array.isArray(layoutResponse.data.data)) {
                const layouts = layoutResponse.data.data;
                if (layouts.length > 0) {
                    floorNumbers = layouts.map(layout => layout.floor);
                    
                    // layoutW와 layoutH가 0인 경우 기본값 사용
                    const firstLayout = layouts[0];
                    initialSize = {
                        width: firstLayout.layoutW || 800,
                        height: firstLayout.layoutH || 600
                    };
                }
            }

            setFloors(floorNumbers);
            setCurrentFloor(Math.min(...floorNumbers));
            setLayoutSize(initialSize);

            // 2. 요소 정보 fetch
            const elementResponse = await api.get(`/api/element/${deptId}`);
            
            // 초기 빈 레이아웃 객체 생성
            const initialElements = {};
            floorNumbers.forEach(floor => {
                initialElements[floor] = [];
            });

            if (elementResponse?.data?.data && Array.isArray(elementResponse.data.data)) {
                const elements = elementResponse.data.data;
                elements.forEach(element => {
                    if (element && element.floor) {
                        const floor = element.floor;
                        if (!initialElements[floor]) {
                            initialElements[floor] = [];
                        }
                        
                        initialElements[floor].push({
                            id: element.elementId || `element-${Date.now()}`,
                            type: getTypeFromElementId(element.elementId || ''),
                            content: element.content || '',
                            layout: {
                                x: element.layoutX || 0,
                                y: element.layoutY || 0,
                                w: element.layoutW || 2,
                                h: element.layoutH || 2
                            }
                        });
                    }
                });
            }

            setLayoutElements(initialElements);

        } catch (error) {
            console.error('데이터를 불러오는 중 오류 발생:', error);
            // 에러 발생시 기본값 설정
            setFloors([1]);
            setCurrentFloor(1);
            setLayoutSize({ width: 800, height: 600 });
            setLayoutElements({ 1: [] });
        } finally {
            setIsLoading(false);
        }
    };

    if (deptId) {
        fetchLayoutData();
    }
}, [deptId]);


    // 층 추가
    const handleAddFloor = () => {
        const currentMaxFloor = floors.reduce((max, floor) => Math.max(max, floor), 0);
        const newFloor = currentMaxFloor + 1;
        setFloors([...floors, newFloor]);
        setLayoutElements(prev => ({
            ...prev,
            [newFloor]: [] // 새로운 층의 빈 레이아웃 초기화
        }));
    };
    //층 수 삭제 핸들러
    const handleRemoveFloor = (floorToRemove) => {
        if (floors.length <= 1) {
            alert('최소 1개의 층은 유지해야 합니다.');
            return;
        }
    
        if (window.confirm(`${floorToRemove}층을 삭제하시겠습니까?`)) {
            const newFloors = floors.filter(f => f !== floorToRemove);
            const { [floorToRemove]: removed, ...remainingLayouts } = layoutElements;
            
            setFloors(newFloors);
            setLayoutElements(remainingLayouts);
            setCurrentFloor(newFloors[0]);
        }
    };
    // 요소 추가
    const handleAddElement = () => {
        if (!newElementContent) return;
    
        const timestamp = Date.now();
        const newElement = {
            id: `${selectedElementType}-${timestamp}`,
            type: selectedElementType,
            content: newElementContent,
            layout: { x: 0, y: 0, w: 2, h: 2 }
        };
    
        setLayoutElements({
            ...layoutElements,
            [currentFloor]: [...layoutElements[currentFloor], newElement]
        });
    
        setNewElementContent('');
        setIsAddModalOpen(false);
    };

    // 레이아웃 변경
    const handleLayoutChange = (floor, newLayout) => {
        const updatedElements = layoutElements[floor].map(item => {
            const layoutItem = newLayout.find(l => l.i === item.id);
            if (layoutItem) {
                return {
                    ...item,
                    layout: {
                        x: layoutItem.x,
                        y: layoutItem.y,
                        w: layoutItem.w,
                        h: layoutItem.h
                    }
                };
            }
            return item;
        });

        setLayoutElements({
            ...layoutElements,
            [floor]: updatedElements
        });
    };
    const handleSave = async () => {
        try {
            // 1. 레이아웃 정보 저장 (각 층의 너비/높이)
            const layoutData = floors.map(floor => ({
                floor: floor,
                layoutW: layoutSize.width,
                layoutH: layoutSize.height
            }));
            const layoutResponse = await api.post(`/api/layout/${deptId}`, layoutData);
            // 2. 요소 정보 저장
            const elementList = [];
            Object.entries(layoutElements).forEach(([floor, elements]) => {
                elements.forEach(el => {
                    elementList.push({
                        floor: parseInt(floor),
                        elementId: el.id,
                        content: el.content,
                        layoutX: el.layout.x,
                        layoutY: el.layout.y,
                        layoutW: el.layout.w,
                        layoutH: el.layout.h
                    });
                });
            });
            const elementResponse = await api.post(`/api/element/${deptId}`, elementList);
            alert('배치도가 저장되었습니다.');
        } catch (error) {
            console.error('저장 중 오류 발생:', error);
            console.error('Error details:', error.response?.data);
            alert('저장 중 오류가 발생했습니다.');
        }
    };
    // 요소 추가 함수도 수정

    return (
        <div className="p-6">
            <div className="mb-8">
                <h1 className="text-2xl font-bold mb-4">지점 배치도 커스터마이징</h1>
                <div className="flex gap-2">
                    <button
                        onClick={handleAddFloor}
                        className="flex items-center gap-2 px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
                    >
                        <PlusCircle className="w-4 h-4" />
                        층 추가
                    </button>
                    <button
                        onClick={() => setIsAddModalOpen(true)}
                        className="flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                    >
                        <PlusCircle className="w-4 h-4" />
                        요소 추가
                    </button>
                    <button
                        onClick={handleSave}
                        className="flex items-center gap-2 px-4 py-2 bg-purple-500 text-white rounded-lg hover:bg-purple-600"
                    >
                        <Save className="w-4 h-4" />
                        저장하기
                    </button>
                </div>
                {/* 배치도 크기 조정 */}
                <div className="flex gap-4 mb-4">
                    <div>
                        <label className="block text-sm font-medium mb-1">가로 크기 (px)</label>
                        <input
                            type="number"
                            value={layoutSize.width}
                            onChange={(e) => setLayoutSize(prev => ({ ...prev, width: parseInt(e.target.value) }))}
                            className="w-32 px-4 py-2 border rounded-lg"
                            min="400"
                            max="2000"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium mb-1">세로 크기 (px)</label>
                        <input
                            type="number"
                            value={layoutSize.height}
                            onChange={(e) => setLayoutSize(prev => ({ ...prev, height: parseInt(e.target.value) }))}
                            className="w-32 px-4 py-2 border rounded-lg"
                            min="300"
                            max="1500"
                        />
                    </div>
                </div>
            </div>

            {/* 층 선택 버튼들 */}
            <div className="mb-4 flex gap-2 items-center">
                {floors.map(floor => (
                    <div key={floor} className="relative">
                        <button
                            onClick={() => setCurrentFloor(floor)}
                            className={`px-6 py-3 rounded-lg ${
                                currentFloor === floor
                                    ? 'bg-blue-500 text-white'
                                    : 'bg-gray-200 hover:bg-gray-300'
                            }`}
                        >
                            {floor}F
                        </button>
                        <button
                            onClick={() => handleRemoveFloor(floor)}
                            className="absolute -top-2 -right-2 p-1 bg-white text-red-500 hover:bg-red-100 rounded-full shadow-md"
                            title="층 삭제"
                        >
                            <MinusCircle className="w-4 h-4" />
                        </button>
                    </div>
                ))}
                <button
                    onClick={handleAddFloor}
                    className="p-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
                    title="층 추가"
                >
                    <PlusCircle className="w-4 h-4" />
                </button>
            </div>

            {/* 레이아웃 그리드 */}
            <div 
                className="bg-white p-4 rounded-lg shadow-lg mb-6" 
                style={{ 
                    width: `${layoutSize.width + 40}px`,
                    height: `${layoutSize.height + 40}px`
                }}
            >
                <GridLayout
                    className="layout"
                    layout={layoutElements[currentFloor].map(item => ({
                        i: item.id,
                        ...item.layout
                    }))}
                    cols={40}  // 컬럼 수 증가
                    rowHeight={20}  // 행 높이 감소
                    width={layoutSize.width}
                    height={layoutSize.height}
                    onLayoutChange={(newLayout) => handleLayoutChange(currentFloor, newLayout)}
                    isResizable={true}  // 크기 조절 가능
                    isBounded={false}  // 경계 제한 없음
                    compactType={null}  // 자동 정렬 비활성화
                    preventCollision={false}  // 충돌 방지 비활성화
                    margin={[5, 5]}
                    containerPadding={[5, 5]}
                >
                    {layoutElements[currentFloor].map((element) => (
                        <div
                            key={element.id}
                            className="border rounded bg-white shadow-sm cursor-move"
                        >
                            <div className="p-2 text-center">
                                {element.type === 'entrance' ? (
                                    <div className="flex flex-col items-center">
                                        <DoorOpen className="w-6 h-6" />
                                        <span>출입구 {element.content}</span>
                                    </div>
                                ) : (
                                    <div className="flex flex-col items-center">
                                        <Monitor className="w-6 h-6" />
                                        <span>창구 {element.content}</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </GridLayout>
            </div>

            {/* 요소 추가 모달 */}
            <Modal
                isOpen={isAddModalOpen}
                onClose={() => setIsAddModalOpen(false)}
                title="새 요소 추가"
            >
                <div className="space-y-4">
                    <div>
                        <label className="block text-sm font-medium mb-1">요소 유형</label>
                        <select
                            value={selectedElementType}
                            onChange={(e) => setSelectedElementType(e.target.value)}
                            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                            {elementTypes.map(type => (
                                <option key={type.id} value={type.id}>
                                    {type.label}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label className="block text-sm font-medium mb-1">
                            {selectedElementType === 'entrance' ? '출입구 이름' : '창구 번호'}
                        </label>
                        <input
                            type="text"
                            value={newElementContent}
                            onChange={(e) => setNewElementContent(e.target.value)}
                            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder={selectedElementType === 'entrance' ? "정문" : "1"}
                        />
                    </div>
                    <button
                        onClick={handleAddElement}
                        className="w-full px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                    >
                        추가하기
                    </button>
                </div>
            </Modal>
        </div>
    );
};

export default BranchLayoutCustom;
