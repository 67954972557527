import { useRef, useEffect } from 'react';

const DropDown = ({ 
  value,
  options,
  isOpen,
  onToggle,
  onChange,
  placeholder,
  width = 'w-[180px]',
  formatOption = (opt) => opt,
  formatSelected = (val) => val
}) => {
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        onToggle(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onToggle]);

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={() => onToggle(!isOpen)}
        className={`flex items-center justify-between px-4 py-2 text-sm bg-white border rounded-lg shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 ${width}`}
      >
        <span>{value ? formatSelected(value) : placeholder}</span>
        <svg
          className={`w-4 h-4 ml-2 transition-transform duration-200 ${
            isOpen ? 'transform rotate-180' : ''
          }`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
        </svg>
      </button>

      {isOpen && (
        <div 
          className={`absolute left-0 z-50 w-full mt-1 bg-white border rounded-lg shadow-lg max-h-60 overflow-y-auto`}
          style={{ minWidth: '100%' }}
        >
          {options.map((option, index) => (
            <button
              key={index}
              onClick={() => {
                onChange(option);
                onToggle(false);
              }}
              className={`block w-full px-4 py-2 text-sm text-left hover:bg-gray-100 focus:outline-none
                ${option === value ? 'bg-gray-50 font-semibold' : ''}
                ${index === 0 ? 'rounded-t-lg' : ''}
                ${index === options.length - 1 ? 'rounded-b-lg' : ''}`}
            >
              {formatOption(option)}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropDown;