export const BusinessNavigationItem = ({ item, activeComponent, setActiveComponent }) => {
    return (
      <li>
        <button 
          onClick={() => setActiveComponent(item.id)}
          className={`flex items-center w-full p-1 sm:p-2 text-gray-900 rounded-lg hover:bg-gray-100 group ${
            activeComponent === item.id ? 'bg-gray-100' : ''
          }`}
        >
          <svg 
            className="flex-shrink-0 w-4 h-4 sm:w-5 sm:h-5 text-gray-500 transition duration-75 group-hover:text-gray-900"
            aria-hidden="true" 
            xmlns="http://www.w3.org/2000/svg" 
            fill="currentColor" 
            viewBox="0 0 20 20"
          >
            {item.icon}
          </svg>
          <span className="flex-1 ms-2 sm:ms-3 text-sm sm:text-base whitespace-nowrap">
            {item.name}
          </span>
          {item.badge && (
            <span className={`inline-flex items-center justify-center ${
              item.badge.color === 'blue' 
                ? 'w-2 h-2 sm:w-3 sm:h-3 p-2 sm:p-3 ms-2 sm:ms-3 text-xs sm:text-sm font-medium text-blue-800 bg-blue-100 rounded-full'
                : 'px-1 sm:px-2 ms-2 sm:ms-3 text-xs sm:text-sm font-medium text-gray-800 bg-gray-100 rounded-full'
            }`}>
              {item.badge.text}
            </span>
          )}
        </button>
      </li>
    );
  };