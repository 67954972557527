import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // useNavigate 추가
import api  from '../../hooks/axios';


function Login() {
  const [bankerId, setBankerId] = useState(''); // 행원번호 상태 추가
  const [userId, setUserId] = useState(''); // 아이디 상태 추가
  const [userPwd, setUserPwd] = useState(''); // 비밀번호 상태 추가
  const [message, setMessage] = useState(''); // 메시지 상태 추가
  const navigate = useNavigate(); // 리다이렉트를 위한 useNavigate 훅


  const handleLogin = async (e) => {
    e.preventDefault(); // 기본 폼 제출 방지

    try {
      const response = await api.post('/api/user/login', {
        bankerId,
        userId,
        userPwd,
      });
      // 로그인 성공 여부 확인
        if (response.data.code === 200) {
        // 로그인 성공 시 원하는 페이지로 리다이렉트
        // Recoil 상태에 사용자 정보 저장
        if (response.data.data.type === 'HEAD') {
          navigate('/dashboard'); // HEAD 타입이면 /hdadmin으로
        } else if (response.data.data.type === 'BUSINESS') {
          navigate('/bsadmin'); // BUSINESS 타입이면 /bsadmin으로
        } else {
          
          setMessage(response.data.error);
        }
      }
      else {
        setMessage(response.data.error);
      }
    } catch (error) {
      console.error('Error during login:', error);
      setMessage('로그인 중 오류가 발생했습니다.');
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-900 p-4 transition-colors duration-200">
      <div className="bg-white dark:bg-gray-800 p-4 sm:p-6 md:p-8 rounded-lg shadow-md w-full max-w-[320px] sm:max-w-[380px] md:max-w-[440px] lg:max-w-[480px] transition-colors duration-200">
        <h1 className="text-2xl sm:text-3xl font-bold text-center mb-4 sm:mb-6 text-gray-900 dark:text-white">관리자 페이지</h1>
        <form> {/* handleLogin 함수 호출 */}
          <div className="mb-3 sm:mb-4">
            <label className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-1 sm:mb-2" htmlFor="bankerId">행원번호</label>
            <input
              className="shadow appearance-none border rounded w-full py-1.5 sm:py-2 px-2 sm:px-3 text-gray-700 dark:text-gray-300 dark:bg-gray-700 dark:border-gray-600 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-500 text-sm sm:text-base"
              id="bankerId"
              type="text"
              placeholder="행원번호를 입력하세요"
              value={bankerId} // 상태 연결
              onChange={(e) => setBankerId(e.target.value)} // 입력값 변경 시 상태 업데이트
            />
          </div>
          <div className="mb-3 sm:mb-4">
            <label className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-1 sm:mb-2" htmlFor="username">아이디</label>
            <input
              className="shadow appearance-none border rounded w-full py-1.5 sm:py-2 px-2 sm:px-3 text-gray-700 dark:text-gray-300 dark:bg-gray-700 dark:border-gray-600 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-500 text-sm sm:text-base"
              id="username"
              type="text"
              placeholder="아이디를 입력하세요"
              value={userId} // 상태 연결
              onChange={(e) => setUserId(e.target.value)} // 입력값 변경 시 상태 업데이트
            />
          </div>
          <div className="mb-4 sm:mb-6">
            <label className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-1 sm:mb-2" htmlFor="password">비밀번호</label>
            <input
              className="shadow appearance-none border rounded w-full py-1.5 sm:py-2 px-2 sm:px-3 text-gray-700 dark:text-gray-300 dark:bg-gray-700 dark:border-gray-600 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-500 text-sm sm:text-base"
              id="password"
              type="password"
              placeholder="비밀번호를 입력하세요"
              value={userPwd} // 상태 연결
              onChange={(e) => setUserPwd(e.target.value)} // 입력값 변경 시 상태 업데이트
            />
          </div>
          {message && (
            <div className="mt-2 text-red-500 text-center">
              {message} {/* 오류 메시지 표시 */}
            </div>
          )}
          <div className="flex items-center justify-between space-x-4">
            <button
              className="flex-grow sm:flex-grow-0 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 sm:px-6 rounded focus:outline-none focus:shadow-outline transition-colors duration-200 text-sm sm:text-base"
              type="submit" onClick={handleLogin}
            >
              로그인
            </button>
          </div>
        </form>
        <div className="mt-4 text-center">
          <Link to="/register" className="text-blue-500 dark:text-blue-400 hover:underline text-sm sm:text-base">
            회원가입
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Login;