import React, { useState } from 'react';
import api  from '../../hooks/axios';
import { Navigate } from "react-router-dom";
function Register() {
  const [bankerId, setBankerId] = useState('');
  const [userId, setUserId] = useState('');
  const [userPwd, setUserPwd] = useState(''); // 비밀번호 상태 추가
  const [confirmPassword, setConfirmPassword] = useState(''); // 비밀번호 재입력 상태 추가
  const [message, setMessage] = useState(''); // 메시지 상태 추가
  const [passwordError, setPasswordError] = useState(''); // 비밀번호 오류 메시지 상태 추가
  const [isSuccess, setIsSuccess] = useState(false); // 성공 여부 상태 추가
  const [completeRegist, setCompletRegist] = useState(false);

  // 조회 버튼 클릭 시 호출되는 함수
  const handleFetchUser = async () => {
    try {
      const response = await api.get(`/api/user/${bankerId}`);
      if (!response.data.data.code === 500) {
        throw new Error('네트워크 응답에 문제가 있습니다.');
      }
      const data = await response.data;
      // 응답 데이터에 따라 메시지 설정
      if (data.data) {

        setMessage('회원가입이 가능합니다.');
        setIsSuccess(true);
      }
      else if (!data.data) {
        setMessage('이미 가입된 회원입니다.');
        setIsSuccess(false);
      }
      else {
        setMessage('행번을 찾을 수 없습니다.');
        setIsSuccess(false);
      }
    } catch (error) {
      console.error('Error fetching user:', error);
      setMessage('행번을 찾을 수 없습니다.');
      setIsSuccess(false);
    }
  };

  // 비밀번호가 일치하는지 확인하는 함수
  const isPasswordValid = () => {
    return userPwd === confirmPassword;
  };

  // 회원가입 요청 처리 함수
  const handleRegister = async (e) => {
    e.preventDefault(); // 기본 폼 제출 방지
    setPasswordError(''); // 오류 메시지 초기화
    if (!isSuccess) {
      setMessage('회원가입을 진행할 수 없습니다. 행번을 먼저 조회하세요');
      return; // isSuccess가 false이면 요청을 보내지 않음
    }
    if (!isPasswordValid()) {
      setPasswordError('비밀번호가 일치하지 않습니다.'); // 비밀번호 불일치 메시지 설정
      return; // 비밀번호가 일치하지 않으면 요청을 보내지 않음
    }
  
    // 회원가입 요청을 보내는 코드 추가
    try {
      const response = await api.post('/api/user/regist', {
        bankerId,
        userId,
        userPwd,
        // 필요한 다른 데이터도 여기에 추가할 수 있습니다.
      });
      // 응답 데이터에 따라 메시지 설정
      if (response.data.code === 200) {
        alert('회원가입이 완료되었습니다.');
        //data.data.
        setCompletRegist(true);

      } else {
        alert('회원가입에 실패했습니다: ' + response.data.message);
      }
    } catch (error) {
      console.error('Error during registration:', error);
      setMessage('회원가입 중 오류가 발생했습니다.');
    }
  };
  // 리다이렉트 처리
  if (completeRegist) {
    return <Navigate to='/' />;
  }
  return (
    
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md w-96">
        <h1 className="text-3xl font-bold text-center mb-6">회원가입</h1>
        
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="bankerId">행원번호</label>
          <div className="flex">
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="bankerId"
              type="text"
              placeholder="행원번호를 입력하세요"
              value={bankerId}
              onChange={(e) => setBankerId(e.target.value)} // 입력값 변경 시 상태 업데이트
            />
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-2 whitespace-nowrap"
              onClick={handleFetchUser} // 버튼 클릭 시 함수 호출
            >
              조회
            </button>
          </div>
        </div>

        {/* 메시지 표시 */}
        {message && (
          <div className={`mt-2 text-center ${isSuccess ? 'text-blue-500' : 'text-red-500'}`}>
            {message}
          </div>
        )}

        <form onSubmit={handleRegister}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="userName">아이디</label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="userName"
              type="text"
              placeholder="아이디를 입력하세요"
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="userPwd">비밀번호</label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="userPwd"
              type="password"
              placeholder="비밀번호를 입력하세요"
              value={userPwd}
              onChange={(e) => setUserPwd(e.target.value)} // 비밀번호 입력 시 상태 업데이트
            />
          </div>

          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="confirmPassword">비밀번호 확인</label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="confirmPassword"
              type="password"
              placeholder="비밀번호를 다시 입력하세요"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)} // 비밀번호 재입력 시 상태 업데이트
            />
            {/* 비밀번호 불일치 메시지 표시 */}
            {passwordError && (
              <div className="mt-2 text-red-500 text-sm text-center">
                {passwordError}
              </div>
            )}
          </div>

            <div className="flex items-center justify-between">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit" // 회원가입 버튼
              >
                회원가입
              </button>
            </div>

            <div className="mt-4 text-center">
              <a className="text-blue-500 hover:underline" href="/">비밀번호 찾기</a>
            </div>
          </form>
        </div>
      </div>
  );
}

export default Register;
