import React, { useState, useEffect, useRef } from 'react';
import api from '../../hooks/axios';
import ReactApexChart from 'react-apexcharts';

const BranchTimeChart = ({ initialYear, sidebarOpen }) => {
   const [selectedYear, setSelectedYear] = useState(initialYear);
   const [isYearDropdownOpen, setIsYearDropdownOpen] = useState(false);
   const dropdownRef = useRef(null);
   const years = [2023, 2024];
   const [chartKey, setChartKey] = useState(0);
   const [branchData, setBranchData] = useState([]);
   const [loading, setLoading] = useState(false);
   const [error, setError] = useState(null);
    
   useEffect(() => {
       const fetchData = async () => {
           setLoading(true);
           try {
               const response = await api.get(`/api/counsel/branch/${selectedYear}`);
               setBranchData(response.data.data || []);
               setError(null);
           } catch (err) {
               setError(err.message);
               setBranchData([]);
           } finally {
               setLoading(false);
           }
       };
   
       fetchData();
   }, [selectedYear]);

   useEffect(() => {
       const handleClickOutside = (event) => {
           if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
               setIsYearDropdownOpen(false);
           }
       };

       document.addEventListener('mousedown', handleClickOutside);
       return () => {
           document.removeEventListener('mousedown', handleClickOutside);
       };
   }, []);
   useEffect(() => {
        const timer = setTimeout(() => {
            setChartKey(prev => prev + 1);
        }, 300); // transition이 끝난 후 리렌더링

        return () => clearTimeout(timer);
    }, [sidebarOpen]);
   const chartOptions = {
       chart: {
           height: 350,
           type: 'line',
           dropShadow: {
               enabled: true,
               color: '#000',
               top: 18,
               left: 7,
               blur: 10,
               opacity: 0.2
           },
           toolbar: {
               show: false
           }
       },
       colors: ['#008FFB', '#FF4560'],
       dataLabels: {
           enabled: true,
           formatter: function(val) {
               return Math.round(val) + '분';
           }
       },
       stroke: {
           width: [3, 3],
           curve: 'smooth'
       },
       title: {
           text: `${selectedYear}년 영업점별 평균 상담/대기 시간`,
           align: 'center',
           margin: 100,  // 여백 추가
           offsetY: 20, // 위치 조정
           style: {
               fontSize: '14px',
               fontWeight: 'bold',
           }
       },
       grid: {
           borderColor: '#e7e7e7',
           row: {
               colors: ['#f3f3f3', 'transparent'],
               opacity: 0.5
           }
       },
       markers: {
           size: 1
       },
       xaxis: {
           categories: branchData.map(item => item.deptName),
           title: {
               text: '영업점'
           }
       },
       yaxis: {
           title: {
               text: '시간 (분)'
           },
           min: 0,
           labels: {
                formatter: function(val) {
                    return Math.round(val);  // y축 라벨도 정수로 표시
                }
            }
       },
       legend: {
           position: 'top',
           horizontalAlign: 'right',
           floating: true,
           offsetY: -25,
           offsetX: -5
       },
       tooltip: {  // 툴팁에도 정수로 표시
            y: {
                formatter: function(val) {
                    return Math.round(val) + '분';
                }
            }
        }
   };

   const chartSeries = [
       {
           name: "평균 상담 시간",
           data: branchData.map(item => Math.round(item.avgCounselTime))
       },
       {
           name: "평균 대기 시간",
           data: branchData.map(item => Math.round(item.avgWaitTime))
       }
   ];

   const renderYearSelector = () => (
       <div className="flex justify-end mb-4">
           <div className="relative" ref={dropdownRef}>
               <button
                   onClick={() => setIsYearDropdownOpen(!isYearDropdownOpen)}
                   className="flex items-center justify-between px-4 py-2 text-sm sm:text-base bg-white border rounded-lg shadow-sm hover:bg-gray-50 min-w-[120px] sm:min-w-[140px] focus:outline-none focus:ring-2 focus:ring-blue-500"
               >
                   <span>{selectedYear}년</span>
                   <svg
                       className={`w-4 h-4 ml-2 transition-transform duration-200 ${
                           isYearDropdownOpen ? 'transform rotate-180' : ''
                       }`}
                       fill="none"
                       stroke="currentColor"
                       viewBox="0 0 24 24"
                   >
                       <path
                           strokeLinecap="round"
                           strokeLinejoin="round"
                           strokeWidth={2}
                           d="M19 9l-7 7-7-7"
                       />
                   </svg>
               </button>

               {isYearDropdownOpen && (
                   <div className="absolute right-0 z-10 w-full mt-1 bg-white border rounded-lg shadow-lg">
                       {years.map((year) => (
                           <button
                               key={year}
                               onClick={() => {
                                   setSelectedYear(year);
                                   setIsYearDropdownOpen(false);
                               }}
                               className={`block w-full px-4 py-2 text-sm sm:text-base text-left hover:bg-gray-100 focus:outline-none
                                   ${year === selectedYear ? 'bg-gray-50 font-semibold' : ''}
                                   ${year === years[0] ? 'rounded-t-lg' : ''}
                                   ${year === years[years.length - 1] ? 'rounded-b-lg' : ''}`}
                           >
                               {year}년
                           </button>
                       ))}
                   </div>
               )}
           </div>
       </div>
   );

   const renderContent = () => {
       if (loading) {
           return (
               <div className="absolute inset-0 flex items-center justify-center">
                   <div className="flex items-center space-x-2">
                       <div className="w-4 h-4 border-2 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
                       <span className="text-gray-500">데이터를 불러오는 중...</span>
                   </div>
               </div>
           );
       }

       if (error) {
           return (
               <div className="absolute inset-0 flex items-center justify-center text-red-500">
                   데이터를 불러오는데 실패했습니다: {error}
               </div>
           );
       }

       if (branchData.length === 0) {
           return (
               <div className="absolute inset-0 flex items-center justify-center">
                   데이터가 없습니다.
               </div>
           );
       }

       return (
           <ReactApexChart
                key={chartKey} // key 추가
               options={{
                   ...chartOptions,
                   chart: {
                       ...chartOptions.chart,
                       animations: {
                           enabled: true,
                           easing: 'easeinout',
                           speed: 300,
                           animateGradually: {
                               enabled: true,
                               delay: 150
                           },
                           dynamicAnimation: {
                               enabled: true,
                               speed: 350
                           }
                       },
                       zoom: {
                           enabled: false
                       },
                       responsive: false,
                   }
               }}
                series={chartSeries} 
                type="line" 
                height={350}
           />
       );
   };

   return (
       <div className="w-full p-4 bg-white rounded-lg shadow">
           {renderYearSelector()}
           <div className="relative w-full">
               {renderContent()}
           </div>
       </div>
   );
};

export default BranchTimeChart;