import { useState, useEffect, useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { userState } from '../state/userState';
import api, { setAuthToken }  from '../hooks/axios';
import { useNavigate } from 'react-router-dom';  // useNavigate import

export const useAutoLogout = () => {
  const user = useRecoilValue(userState);
  const setUser = useSetRecoilState(userState);
  const [timeLeft, setTimeLeft] = useState(300);
  const navigate = useNavigate();  // useNavigate 훅 사용

  useEffect(() => {
    if (user?.accessToken) {
        setAuthToken(user.accessToken);
    }
  }, [user?.accessToken]);
  
  
  
  // 자동 로그아웃 함수
  const autoLogout = useCallback(async () => {
    try {
        await api.get('/api/user/logout');
        setUser({
            type: null,
            userName: "",
            accessToken: "",
        });
        alert("로그아웃 되었습니다.");
        navigate('/');
    } catch (error) {
        console.error('자동 로그아웃 실패:', error);
    }
  }, [setUser, navigate]);
    
  // 타이머 리셋 함수
  const resetTimer = useCallback(() => {
    setTimeLeft(300);
  }, []);

  // 수동 로그아웃 함수
  const handleLogout = async () => {
    try {
      await api.get('/api/user/logout');
      setUser({
            type: null,
            userName: "",
            accessToken: "",
      });
      alert("로그아웃 되었습니다.");
      navigate('/');
    } catch (error) {
      console.error('로그아웃 실패:', error);
    }
  };

  // 활동 감지 및 타이머 리셋
  useEffect(() => {
    if (!user?.userName) return; // 로그인하지 않은 경우 타이머 작동하지 않음

    const resetTimerOnActivity = () => resetTimer();

    window.addEventListener('mousemove', resetTimerOnActivity);
    window.addEventListener('keypress', resetTimerOnActivity);
    window.addEventListener('click', resetTimerOnActivity);
    window.addEventListener('scroll', resetTimerOnActivity);

    return () => {
      window.removeEventListener('mousemove', resetTimerOnActivity);
      window.removeEventListener('keypress', resetTimerOnActivity);
      window.removeEventListener('click', resetTimerOnActivity);
      window.removeEventListener('scroll', resetTimerOnActivity);
    };
  }, [resetTimer, user]);

  // 타이머 실행
  useEffect(() => {
    if (user?.userName) {
      const timer = setInterval(() => {
        setTimeLeft(prev => {
          if (prev <= 1) {
            clearInterval(timer);
            autoLogout();
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [user, autoLogout]);

  // 시간 포맷팅 함수
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  // 타이머 UI 컴포넌트
    const TimerDisplay = () => {
        if (!user?.userName) return null;
        return (
            <div className="flex items-center space-x-4">
                <div className="text-black text-xs sm:text-sm md:text-base whitespace-nowrap">
                    남은 시간: {formatTime(timeLeft)}
                </div>
                <button
                    onClick={resetTimer}
                    className="text-blue-600 hover:text-blue-800 text-xs sm:text-sm md:text-base whitespace-nowrap"
                >
                    로그인 연장
                </button>
                <button
                    onClick={handleLogout}
                    className="text-red-600 hover:text-red-800 text-xs sm:text-sm md:text-base whitespace-nowrap"
                >
                    로그아웃
                </button>
            </div>
        );
    };


  return {
    timeLeft,
    formatTime,
    resetTimer,
    handleLogout,
    TimerDisplay
  };
};