import React from 'react';
import logo from '../assets/logo1.png'; // 로고 이미지 경로
import '../css/Navbar.css';
import { Link, useNavigate } from 'react-router-dom';
import { userState } from '../state/userState';
import { useRecoilValue } from 'recoil';
import { useAutoLogout } from '../hooks/setAutoLogout';

function Navbar() {
  const user = useRecoilValue(userState); // Recoil에서 사용자 상태 가져오기
  const { TimerDisplay } = useAutoLogout();
  const navigate = useNavigate();  // useNavigate 추가
  const handleLogoClick = () => navigate('/');
  const handleTitleClick = () => {
    if (user && user.userName && user.type === 'HEAD') {
      navigate('/dashboard');
    } else if (user && user.type === 'BUSINESS') {
      navigate('/bsadmin');
    } else {
      navigate('/');
    }
  };
  
  return (
      
      <nav className="relative z-50 bg-white shadow-sm px-4 py-2">
      <div className="max-w-7xl mx-auto">
        <div className="hidden sm:flex items-center justify-between">
          {/* 로고 */}
          <div className="flex-shrink-0">
          <button onClick={handleLogoClick} className="flex items-center">
              <img 
                src={logo} 
                alt="Company Logo" 
                className="h-8 md:h-10 w-auto object-contain"
              />
            </button>
          </div>

          {/* 타이틀 */}
          <div className="flex-1 flex justify-center">
          <button 
              onClick={handleTitleClick}
              className="title text-sm sm:text-base md:text-lg lg:text-xl whitespace-nowrap"
            >
              {user && user.type === 'HEAD' 
                ? '대시보드 시스템'
                : user && user.type === 'BUSINESS'
                ? '영업점 시스템'
                : '관리자 시스템'
              }
            </button>
        </div> 

          {/* 유저 정보 */}
          <div className="flex-shrink-0">
            {user && user.userName && user.type ? (
              <div className="flex items-center gap-2">
                <div className="welcome-text text-xs md:text-sm lg:text-base">
                  {user.userName}님 환영합니다.
                </div>
                <div className="text-xs md:text-sm lg:text-base">
                  <TimerDisplay />
                </div>
              </div>
            ) : (
              <ul className="flex items-center gap-2">
                <li>
                  <Link 
                    to="/login" 
                    className="auth-link login-link text-xs md:text-sm lg:text-base px-3 py-1"
                  >
                    로그인
                  </Link>
                </li>
                <li>
                  <Link 
                    to="/register" 
                    className="auth-link register-link text-xs md:text-sm lg:text-base px-3 py-1"
                  >
                    회원가입
                  </Link>
                </li>
              </ul>
            )}
          </div>
        </div>

        {/* 모바일 네비게이션 */}
        <div className="sm:hidden">
          <div className="flex items-center justify-between">
            {/* 로고 */}
            <div className="flex-shrink-0">
            <button onClick={handleLogoClick} className="flex items-center">
                <img 
                  src={logo} 
                  alt="Company Logo" 
                  className="h-6 w-auto object-contain"
                />
              </button>
            </div>

            {/* 유저 정보 */}
            <div className="flex items-center">
              {user && user.userName && user.type ? (
                <div className="flex items-center gap-1">
                  <div className="welcome-text text-xs">
                    {user.userName}
                  </div>
                  <div className="text-xs">
                    <TimerDisplay />
                  </div>
                </div>
              ) : (
                <ul className="flex items-center gap-1">
                  <li>
                    <Link 
                      to="/login" 
                      className="auth-link login-link text-xs px-2 py-1"
                    >
                      로그인
                    </Link>
                  </li>
                  <li>
                    <Link 
                      to="/register" 
                      className="auth-link register-link text-xs px-2 py-1"
                    >
                      회원가입
                    </Link>
                  </li>
                </ul>
              )}
            </div>
          </div>

          {/* 모바일 타이틀 */}
          <div className="text-center mt-2">
            <button 
              onClick={handleTitleClick}
              className="title text-sm font-semibold"
            >
              관리자 시스템
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;