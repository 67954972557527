import videoSource from '../assets/vedio.mp4';

const BackgroundVideo = () => {

    return (
      <div className="absolute inset-0 flex flex-col">
        <div className="flex-1 relative overflow-hidden p-4">
          <div className="video-wrapper relative h-full rounded-lg overflow-hidden">
            <video
              autoPlay
              loop
              muted
              playsInline
              preload="auto"  // 추가: 비디오 미리 로드
              loading="lazy"  // 추가: 필요할 때만 로드
              className="video-element"
              // src="https://infra1-static.recruiter.co.kr/builder/2024/09/09/fc52a369-f01a-4f45-b2aa-2e0c3cf23893.mp4"
              src={videoSource}
            />
          </div>
        </div>
      </div>
    );
};
  
export default BackgroundVideo;