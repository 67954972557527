import { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import api from '../hooks/axios';
import { userState } from "../state/userState";
import { useNavigate } from 'react-router-dom';  // useNavigate import

const InitialUserLoader = ({ type, children }) => {
  const [isVaild, setIsVaild] = useState(false);
  const setUser = useSetRecoilState(userState);
  const navigate = useNavigate();  // useNavigate 훅 사용
  useEffect(() => {
    async function test() {
      try {
        
        const response = await api.get('/api/user/token/valid');
         //사용자 정보 세팅
        const data = response.data.data;
        
        if (type === 'HEAD' || type === 'BUSINESS') {
          if (data.type !== type) {
            alert("잘못된 권한입니다.!");
            navigate('/');
            return;
          }
        }
        // 사용자 정보를 Recoil 상태에 저장
        setUser({
          userName: data.userName,
          type: data.type,
          accessToken: data.accessToken
        });
        setIsVaild(true);
      } catch (error) {
        console.log(error);
      }
    }
    test();
  }, [navigate, setUser, type]);
  return <>{isVaild && children }</>;
};

export default InitialUserLoader;
