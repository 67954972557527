import React, { useState, useEffect } from 'react';
import { Line, Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import api from '../../hooks/axios';
import DropDown from '../DropDown';

// Chart.js 등록
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BranchWdcdTimeStats = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [branches, setBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // 업무 유형 매핑
  const workTypeMap = {
    "02": "일반업무",
    "03": "대출업무",
    "04": "환전업무",
    "05": "대구로페이"
  };

  // 영업점 정보 가져오기
  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const response = await api.get('/api/bank/info');
        if (response.data.code === 200) {
          const branchData = response.data.data;
          setBranches(branchData);
          if (branchData.length > 0) {
            setSelectedBranch(branchData[0]);
          }
        }
      } catch (err) {
        console.error('Error fetching branches:', err);
        setError('영업점 정보를 불러오는데 실패했습니다.');
      }
    };

    fetchBranches();
  }, []);

  // 선택된 영업점의 데이터 가져오기
  useEffect(() => {
    const fetchData = async () => {
      if (!selectedBranch) return;

      try {
        setLoading(true);
        const response = await api.get(`/api/counsel/branch/wdcd/${selectedBranch.deptId}`);
        if (response.data.code === 200) {
          const sortedData = response.data.data.sort((a, b) => 
            a.workType.localeCompare(b.workType)
          );
          setData(sortedData);
        }
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('데이터를 불러오는데 실패했습니다.');
      } finally {
        setLoading(false);
      }
    };

    if (selectedBranch) {
      fetchData();
    }
  }, [selectedBranch]);

  const chartData = {
    labels: data.map(item => workTypeMap[item.workType] || item.workType),
    datasets: [
      {
        type: 'bar',
        label: '처리 건수',
        data: data.map(item => item.totalCount),
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        borderColor: 'rgb(53, 162, 235)',
        borderWidth: 1,
        yAxisID: 'y'
      },
      {
        type: 'line',
        label: '평균 대기시간',
        data: data.map(item => Number(item.avgWaitTime.toFixed(1))),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        yAxisID: 'y1',
        tension: 0.4
      }
    ]
  };

  const options = {
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      title: {
        display: true,
        text: '업무 유형별 처리 건수 및 평균 대기시간',
        font: {
          size: 16,
          weight: 'bold'
        }
      },
      legend: {
        position: 'bottom'
      }
    },
    scales: {
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        title: {
          display: true,
          text: '처리 건수',
          font: {
            size: 12,
            weight: 'bold'
          }
        }
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
        title: {
          display: true,
          text: '평균 대기시간 (분)',
          font: {
            size: 12,
            weight: 'bold'
          }
        },
        grid: {
          drawOnChartArea: false
        }
      }
    }
  };

  const renderContent = () => {
    if (loading) {
      return (
        <div className="flex items-center justify-center h-[400px]">
          <div className="flex items-center space-x-2">
            <div className="w-4 h-4 border-2 border-blue-500 border-t-transparent rounded-full animate-spin" />
            <span className="text-gray-500">데이터를 불러오는 중...</span>
          </div>
        </div>
      );
    }

    if (error) {
      return (
        <div className="flex items-center justify-center h-[400px] text-red-500">
          {error}
        </div>
      );
    }

    if (!data || data.length === 0) {
      return (
        <div className="flex items-center justify-center h-[400px] text-gray-500">
          데이터가 없습니다.
        </div>
      );
    }

    return (
      <div className="h-[400px] w-full">
        <Line data={chartData} options={options} />
      </div>
    );
  };

  return (
    <div className="w-full p-4 bg-white rounded-lg shadow">
      <DropDown
        value={selectedBranch}
        options={branches}
        isOpen={isDropdownOpen}
        onToggle={setIsDropdownOpen}
        onChange={setSelectedBranch}
        placeholder="영업점 선택"
        width="w-full sm:w-[200px]"
        formatOption={(branch) => branch.deptName}
        formatSelected={(branch) => branch.deptName}
      />
      {renderContent()}
    </div>
  );
};

export default BranchWdcdTimeStats;