import { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import api from '../../hooks/axios';
import DropDown from '../DropDown';

const BranchDailyStatsChart = () => {
  // State 관리
  const [mounted, setMounted] = useState(false);
  const [branches, setBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState({ id: "2", name: "영업점" });
  const [selectedYear, setSelectedYear] = useState(2024);
  const [selectedMonth, setSelectedMonth] = useState(11);
  const [dailyData, setDailyData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const [isBranchOpen, setIsBranchOpen] = useState(false);
  const [isYearOpen, setIsYearOpen] = useState(false);
  const [isMonthOpen, setIsMonthOpen] = useState(false);

  const years = [2023, 2024];
  const months = Array.from({length: 12}, (_, i) => i + 1);

  // mounted 상태 관리
  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  // 지점 정보 가져오기
  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const response = await api.get('/api/bank/info');
        console.log("API Response:", response.data);
        
        if (response.data.code === 200 && response.data.data) {
          const branchData = response.data.data;
          const parsedBranches = branchData.map(branch => ({
            id: branch.deptId,
            name: branch.deptName
          }));
          setBranches(parsedBranches);
        }
      } catch (err) {
        console.error('Error fetching branches:', err);
        setError('영업점 정보를 불러오는데 실패했습니다.');
      } finally {
        setLoading(false);
      }
    };

    fetchBranches();
  }, []);

  // 초기 데이터 로드
  useEffect(() => {
    if (mounted) {
      handleSearch();
    }
  }, [mounted]);

  // 데이터 검색 핸들러
  const handleSearch = async () => {
    try {
      setLoading(true);
      const response = await api.post('/api/counsel/branch/daily', {
        deptId: selectedBranch.id,
        year: selectedYear,
        month: selectedMonth
      });

      if (response.data.code === 200) {
        const processedData = processData(response.data.data);
        setDailyData(processedData);
        setError(null);
      }
    } catch (err) {
      console.error('Error fetching daily data:', err);
      setError('데이터를 불러오는데 실패했습니다.');
      setDailyData([]);
    } finally {
      setLoading(false);
    }
  };

  // 데이터 처리 함수
  const processData = (rawData) => {
    const allDays = {};
    for (let i = 1; i <= 31; i++) {
      allDays[i] = 0;
    }

    rawData.forEach(item => {
      allDays[item.day] = item.count;
    });

    const lastDay = new Date(selectedYear, selectedMonth, 0).getDate();
    const processedData = [];
    let maxCount = 0;

    for (let i = 1; i <= lastDay; i++) {
      if (allDays[i] > maxCount) {
        maxCount = allDays[i];
      }
    }

    for (let i = 1; i <= lastDay; i++) {
      processedData.push({
        day: i,
        count: allDays[i],
        isMax: allDays[i] === maxCount && maxCount > 0
      });
    }

    return processedData;
  };

  // 차트 옵션 설정
  const chartOptions = {
    chart: {
      type: 'bar',
      height: '100%',
      toolbar: { show: true },
      events: {
        mounted: function(chartContext) {
          chartContext.windowResizeHandler();
        }
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        dataLabels: { position: 'top' },
        colors: {
          ranges: [{
            from: 0,
            to: 0,
            color: '#E0E0E0'
          }]
        }
      }
    },
    dataLabels: {
      enabled: true,
      formatter: val => val + "명",
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: ["#304758"]
      }
    },
    xaxis: {
      categories: dailyData.map(item => item.day + '일'),
      position: 'bottom',
      axisBorder: { show: false },
      axisTicks: { show: false },
      title: {
        text: '일자',
        offsetY: 2
      },
      labels: {
        rotate: -45,
        rotateAlways: true,
        style: {
          fontSize: '10px',
          fontWeight: 400
        }
      }
    },
    yaxis: {
      title: {
        text: '고객 수',
        style: { fontSize: '12px' }
      },
      tickAmount: 5,
      min: 0,
      labels: {
        formatter: val => Math.floor(val),
        style: { fontSize: '10px' }
      },
      forceNiceScale: true
    },
    title: {
      text: `${selectedYear}년 ${selectedMonth}월 일별 고객 현황`,
      align: 'center',
      style: {
        fontSize: '14px',
        fontWeight: 'bold'
      }
    },
    tooltip: {
      y: {
        formatter: val => val + "명"
      }
    },
    colors: dailyData.map(data => data.isMax ? '#FF0000' : '#008FFB')
  };

  const chartSeries = [{
    name: '고객 수',
    data: dailyData.map(item => item.count)
  }];

  // 컨텐츠 렌더링 함수
  const renderContent = () => {
    if (!mounted) return null;
    
    if (loading) {
      return (
        <div className="flex items-center justify-center h-[400px]">
          <div className="flex items-center space-x-2">
            <div className="w-4 h-4 border-2 border-blue-500 border-t-transparent rounded-full animate-spin" />
            <span className="text-gray-500">데이터를 불러오는 중...</span>
          </div>
        </div>
      );
    }

    if (error) {
      return (
        <div className="flex items-center justify-center h-[400px] text-red-500">
          {error}
        </div>
      );
    }

    if (dailyData.length === 0) {
      return (
        <div className="flex items-center justify-center h-[400px] text-gray-500">
          데이터가 없습니다.
        </div>
      );
    }

    return (
      <ReactApexChart
        options={chartOptions}
        series={chartSeries}
        type="bar"
        height="100%"
      />
    );
  };

  // 메인 렌더링
  return (
    <div className="flex flex-col space-y-4">
      <div className="relative z-10">
        <div className="flex flex-wrap gap-2 sm:gap-4">
          <div className="w-full sm:w-auto">
            <DropDown
              value={selectedBranch}
              options={branches}
              isOpen={isBranchOpen}
              onToggle={setIsBranchOpen}
              onChange={setSelectedBranch}
              placeholder="영업점 선택"
              width="w-full sm:w-[200px]"
              formatOption={(branch) => branch.name}
              formatSelected={(branch) => branch.name}
            />
          </div>

          <div className="flex gap-2 w-full sm:w-auto">
            <div className="flex-1 sm:flex-none">
              <DropDown
                value={selectedYear}
                options={years}
                isOpen={isYearOpen}
                onToggle={setIsYearOpen}
                onChange={setSelectedYear}
                width="w-full sm:w-[120px]"
                formatOption={(year) => `${year}년`}
                formatSelected={(year) => `${year}년`}
              />
            </div>
            <div className="flex-1 sm:flex-none">
              <DropDown
                value={selectedMonth}
                options={months}
                isOpen={isMonthOpen}
                onToggle={setIsMonthOpen}
                onChange={setSelectedMonth}
                width="w-full sm:w-[120px]"
                formatOption={(month) => `${month}월`}
                formatSelected={(month) => `${month}월`}
              />
            </div>
          </div>

          <button
            onClick={handleSearch}
            className="w-full sm:w-auto px-6 py-2 text-sm bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 whitespace-nowrap"
          >
            조회
          </button>
        </div>
      </div>
      
      <div className="h-[400px]">
        {renderContent()}
      </div>
    </div>
  );
};

export default BranchDailyStatsChart;