import axios from 'axios';

const api = axios.create({
    // baseURL: 'http://localhost:8081',
    baseURL: 'https://wlgns1718.cafe24.com',
    withCredentials: true
});

// 토큰 설정 함수 추가
const setAuthToken = (token) => {
    if (token) {
        api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        
    } else {
        delete api.defaults.headers.common['Authorization'];
    }
};

api.interceptors.request.use(
    (config) => {
        
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export {api as default, setAuthToken };