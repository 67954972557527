// KioskCustom.jsx
import React, { useState, useEffect } from 'react';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { XCircle, SaveIcon } from 'lucide-react';
import { StrictModeDroppable } from './StrictModeDroppable';
import logo1 from '../../assets/logo1.png';
import aiImage from '../../assets/ai.png';
import api from '../../hooks/axios';

const KioskCustom = ({ deptId, deptName, activeComponent }) => {
    // 활성화된 버튼과 비활성화된 버튼 상태 관리
    // 모든 버튼의 기본 정보 객체
    const buttonInfo = {
        normal: { name: '일반\n업무', color: 'bg-[#98D8AA]' },
        loan: { name: '대출\n업무', color: 'bg-[#94B9F0]' },
        exchange: { name: '환전\n업무', color: 'bg-[#FFB6B6]' },
        pay: { name: '대구로페이\n업무', color: 'bg-[#FFE69A]' },
        corLoan: { name: '기업대출\n업무', color: 'bg-[#B4A7D6]' }
    };

    // 상태 관리
    const [activeButtons, setActiveButtons] = useState([]);
    const [inactiveButtons, setInactiveButtons] = useState([]);
    const [loading, setLoading] = useState(true);

    // 데이터 로드
    useEffect(() => {
        const loadKioskSettings = async () => {
            if (!deptId || activeComponent !== 'kiosk') return;

            try {
                setLoading(true);
                const response = await api.get(`/api/kiosk/${deptId}`);
                
                if (response.data.code === 200) {
                    const data = response.data.data;
                    const active = [];
                    const inactive = [];

                    // 데이터를 순회하면서 활성/비활성 버튼 분류
                    Object.entries(data).forEach(([key, value]) => {
                        if (buttonInfo[key]) {  // buttonInfo에 있는 키만 처리
                            const buttonData = {
                                id: key,
                                ...buttonInfo[key]
                            };

                            if (value > 0) {
                                // value(순서) 값을 기준으로 적절한 위치에 삽입
                                active[value - 1] = buttonData;
                            } else {
                                inactive.push(buttonData);
                            }
                        }
                    });

                    // active 배열에서 빈 슬롯 제거
                    setActiveButtons(active.filter(Boolean));
                    setInactiveButtons(inactive);
                }
            } catch (error) {
                console.error('키오스크 설정 로드 중 오류 발생:', error);
                // 오류 발생 시 기본 상태로 설정
                setActiveButtons([]);
                setInactiveButtons(Object.entries(buttonInfo).map(([id, info]) => ({
                    id,
                    ...info
                })));
            } finally {
                setLoading(false);
            }
        };

        loadKioskSettings();

        return () => {
            setLoading(true);  // 로딩 상태 재설정
            setActiveButtons([]); // 버튼 상태 초기화
            setInactiveButtons([]);
        };
    }, [deptId, activeComponent]);

    // 버튼 제거 핸들러
    const handleRemoveButton = (buttonIndex) => {
        const removedButton = activeButtons[buttonIndex];
        setActiveButtons(buttons => buttons.filter((_, index) => index !== buttonIndex));
        setInactiveButtons(buttons => [...buttons, removedButton]);
    };

    // 드래그 종료 핸들러
    const handleDragEnd = (result) => {
        if (!result.destination) return;

        const { source, destination } = result;

        if (source.droppableId === destination.droppableId) {
            const items = source.droppableId === 'active' 
                ? Array.from(activeButtons)
                : Array.from(inactiveButtons);
            
            const [reorderedItem] = items.splice(source.index, 1);
            items.splice(destination.index, 0, reorderedItem);

            if (source.droppableId === 'active') {
                setActiveButtons(items);
            } else {
                setInactiveButtons(items);
            }
        } else {
            const sourceItems = Array.from(
                source.droppableId === 'active' ? activeButtons : inactiveButtons
            );
            const destItems = Array.from(
                destination.droppableId === 'active' ? activeButtons : inactiveButtons
            );

            const [movedItem] = sourceItems.splice(source.index, 1);
            destItems.splice(destination.index, 0, movedItem);

            if (source.droppableId === 'active') {
                setActiveButtons(sourceItems);
                setInactiveButtons(destItems);
            } else {
                setActiveButtons(destItems);
                setInactiveButtons(sourceItems);
            }
        }
    };

    const handleSave = async () => {
        try {
            const initialData = {
                deptId: deptId,
                normal: 0,
                loan: 0,
                pay: 0,
                exchange: 0,
                corLoan: 0
            };
             // activeButtons의 현재 순서대로 값을 설정 (1부터 시작)
            const kioskData = activeButtons.reduce((acc, button, index) => {
                return {
                    ...acc,
                    [button.id]: index + 1 // 활성화된 버튼에 1부터 시작하는 순서 부여
                };
            }, initialData);
            
            const response = await api.put(`/api/kiosk/${deptId}`, kioskData);
            
            if (response.data.code === 200) {
                alert('키오스크 설정이 저장되었습니다.');
            }
        } catch (error) {
            console.error('저장 중 오류 발생:', error);
            alert('저장 중 오류가 발생했습니다.');
        }
    };

    if (loading) {
        return <div>로딩 중...</div>;
    }

    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <div className="w-[1080px] h-[1920px] overflow-auto">
                <div className="flex flex-col h-full">
                    {/* 상단 섹션 */}
                    <div className="w-full h-[200px] flex flex-col justify-center items-center mt-10">
                        <div className="mb-8">
                            <img 
                                src={logo1} 
                                alt="Bank Logo"
                                className="h-[100px] w-auto object-contain"
                            />
                        </div>
                        <h1 className="text-5xl font-bold text-gray-800 mt-4">{deptName}</h1>
                    </div>

                    {/* 메인 컨텐츠 섹션 */}
                    <div className="flex justify-center flex-1">
                        {/* 이미지 섹션 */}
                        <div className="w-[500px] flex justify-center items-center">
                            <div className="w-full h-[1300px]">
                                <img 
                                    src={aiImage} 
                                    alt="Bank Service"
                                    className="w-full h-full object-cover"
                                />
                            </div>
                        </div>

                        {/* 활성화된 버튼 섹션 */}
                        <div className="w-[520px] flex flex-col">
                            <StrictModeDroppable droppableId="active">
                                {(provided, snapshot) => (
                                    <div 
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        className={`flex-1 flex items-center justify-center ${
                                            snapshot.isDraggingOver ? 'bg-gray-100' : ''
                                        }`}
                                    >
                                        <div className="w-full flex justify-center">
                                            <div className="flex flex-col gap-10">
                                                {activeButtons.map((button, index) => (
                                                    <Draggable 
                                                        key={button.id} 
                                                        draggableId={button.id} 
                                                        index={index}
                                                    >
                                                        {(provided, snapshot) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                className="relative"
                                                                style={{
                                                                    ...provided.draggableProps.style,
                                                                    opacity: snapshot.isDragging ? 0.5 : 1
                                                                }}
                                                            >
                                                                <div className={`p-8 rounded-2xl cursor-move shadow-lg ${button.color} w-[250px] h-[200px] flex items-center justify-center`}>
                                                                    <span className="text-4xl font-bold text-white whitespace-pre-line text-center">
                                                                        {button.name}
                                                                    </span>
                                                                </div>
                                                                <button
                                                                    onClick={() => handleRemoveButton(index)}
                                                                    className="absolute -top-2 -right-2 text-red-500 hover:text-red-700"
                                                                >
                                                                    <XCircle className="w-8 h-8" />
                                                                </button>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </StrictModeDroppable>
                        </div>
                    </div>

                    {/* 비활성화된 버튼 섹션 */}
                    <div className="w-full min-h-[200px] bg-gray-100 p-4">
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-2xl font-bold mb-4">비활성화된 버튼</h2>
                            <button
                                onClick={handleSave}
                                className="flex items-center gap-2 px-6 py-3 bg-blue-500 hover:bg-blue-600 text-white rounded-lg transition-colors shadow-md"
                            >
                                <SaveIcon className="w-5 h-5" />
                                <span className="font-bold">저장하기</span>
                            </button>
                        </div>
                            <StrictModeDroppable droppableId="inactive" direction="horizontal">
                            {(provided, snapshot) => (
                            <div 
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                className={`flex gap-4 flex-wrap min-h-[120px] ${
                                    snapshot.isDraggingOver ? 'bg-gray-200' : ''
                                }`}
                            >
                                {inactiveButtons.map((button, index) => (
                                <Draggable 
                                    key={button.id} 
                                    draggableId={button.id} 
                                    index={index}
                                >
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            className={`p-4 rounded-lg ${button.color} w-[150px] h-[100px] flex items-center justify-center`}
                                            style={{
                                                ...provided.draggableProps.style,
                                                opacity: snapshot.isDragging ? 0.5 : 1
                                            }}
                                        >
                                            <span className="text-2xl font-bold text-white whitespace-pre-line text-center">
                                                {button.name}
                                            </span>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                                </div>
                            )}
                        </StrictModeDroppable>
                    </div>
                </div>
            </div>
        </DragDropContext>
    );
};

export default KioskCustom;