

import React from 'react';
import Navbar from "./components/Navbar";
import Login from "./pages/login/Login";
import { BrowserRouter as Router, Route, Routes  } from "react-router-dom";
import { RecoilRoot } from 'recoil';
import Regist from "./pages/login/Regist";
import Business from './pages/business/Business';
import InitialUserLoader from "./state/InitialUserLoader";
import Home from "./pages/home/Home";
import DashBoard from "./pages/DashBoard/DashBoard";

function App() {
  return (
    <div className="App">
        
      <Router>
        <RecoilRoot>
            <Navbar/>
          <Routes>
                <Route path="/" element={<InitialUserLoader type="ALL"><Home /> </InitialUserLoader>} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Regist />} />
                <Route path="/bsadmin/*" element={<InitialUserLoader type="BUSINESS"><Business /></InitialUserLoader>} />
                <Route path="/dashboard/*" element={<InitialUserLoader type="HEAD"><DashBoard/></InitialUserLoader>}/>   
            {/* <Route path="/" element={<HeadAdmin/>} /> */}
            </Routes>
        </RecoilRoot>
      </Router>
    </div>
  );
}
export default App;